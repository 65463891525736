import React, { Component } from 'react'
import get from 'lodash/get'

class SolutionCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {curCat: this.props.curCat};
  }
  render() {
    // fetches all content passed in via the component declaration and sorts based on order defined in contentful
    const companies = get(this, 'props.companies.edges');
    const category = get(this, 'props.category');
    const sorted = companies.sort(function(a,b) {
      return b.node.order - a.node.order;
    });
    
    // fetches images from the component declaration
    const openIconBlue = get(this, 'props.iconBlue');
    const cornIcon = get(this, 'props.cornIcon');
    const soyIcon = get(this, 'props.soyIcon');
    const peanutIcon = get(this, 'props.peanutIcon');
    const cottonIcon = get(this, 'props.cottonIcon');

    // counter for company categories
    let comingCount = 0;

    // outputs a grey block with the proper info
    const company = sorted.reverse().map((company) => {
      if (company.node.type.indexOf(category) >= 0) {
        comingCount = 0;
        return (
          <div className="company" key={company.node.id}>
            <div className="logo">
              <img alt={company.node.logo.description} src={company.node.logo.file.url} />
            </div>
            <div className="company-desc">
              <p className="text-left" dangerouslySetInnerHTML={{__html: company.node.copy.copy}}></p>
              {cornIcon.file.url !== null && company.node.crops.indexOf("corn") >= 0 && (
                <img alt="" className="crop-icon" src={cornIcon.file.url} />
              )}
              {soyIcon.file.url !== null && company.node.crops.indexOf("soybean") >= 0 && (
                <img alt="" className="crop-icon" src={soyIcon.file.url} />
              )}
              {cottonIcon.file.url !== null && company.node.crops.indexOf("cotton") >= 0 && (
                <img alt="" className="crop-icon" src={cottonIcon.file.url} />
              )}
              {peanutIcon.file.url !== null && company.node.crops.indexOf("peanut") >= 0 && (
                <img alt="" className="crop-icon" src={peanutIcon.file.url} />
              )}

              {/*need to get the company link out of the tabindex
              pass current state.whichActive as prop to this component and toggle tabindex -1/0 based on that state*/}
              {company.node.linkUrl !== null && (
                <a href={company.node.linkUrl} target="_blank" rel="noopener noreferrer" tabIndex={(this.props.curCat === category) ? "0" : "-1"}>Learn More
                  <img alt="" className="open-icon" src={openIconBlue.file.url} />
                </a>
              )}

              {/*Need to add a footnote to only certain products for now*/}
              {(company.node.name === 'RidomilGold GR' || 
                company.node.name === 'SmartChoice HC' || 
                company.node.name === 'Thimet 20-G') && (
                <p><br/><i>Pending registration in SmartCartridge</i></p>
              )}
            </div>
          </div>
      )} else {
        comingCount++;
      }
      if (comingCount === sorted.length) {
        return (
          <div className="company">
            <p><strong>Coming Soon</strong></p>
          </div>
        )
      }
    });
    return (
      <>{company}</>
    )
  }
};

export default SolutionCompany;