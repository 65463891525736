import * as React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import Img from "gatsby-image"
import SolutionCompany from "../../components/solutionCompany"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Hero from "../../components/hero"
import "../../scss/pages/solutions.scss"

class SolutionsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {whichActive: false};
    this.activateSolution = this.activateSolution.bind(this);
  }
  keySolution = event => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      this.activateSolution(event);
    }
  }
  activateSolution = event => {
    let target = event.target.closest('.solutions-class');
    if (this.state.whichActive === target.id) {
      this.setState(whichActive => {
        return {whichActive: false}
      });
    } else {
      this.setState(whichActive => {
        if (target.parentNode.previousElementSibling !== null) {
          setTimeout(function() {target.parentNode.previousElementSibling.scrollIntoView()}, 500);
        } else {
          setTimeout(function() {target.parentNode.scrollIntoView()}, 500);
        }
        return {whichActive: target.id}
      });
    }
  }
  componentDidMount() {
    // Replacing &reg; elements
    let regElements = document.querySelectorAll("main h1 span, main h2 + p"); 
    regElements.forEach(function(el) {
      el.innerHTML = el.innerHTML.replace(/®/g, "<sup>&reg;</sup>");
    })

    let elText = document.querySelectorAll("main h1 span, .company p"); 
    elText.forEach(function(el) {
      el.innerHTML = el.innerHTML.replace('Container Technology', "Container&nbsp;Technology");
      el.innerHTML = el.innerHTML.replace('traits alone', "traits&nbsp;alone");
    })

    // Addition footer text
    const footerText = document.querySelector(".footer__copyright:last-of-type"); 
    const footerAddition = '<p class="footer__copyright">AZTEC, COUNTER, Force and SMARTCHOICE are restricted use pesticides. Important: Always read and follow label instructions. See label for listed pests. Some products may not be registered for sale or use in all states or counties. Please check with your state agency responsible for pesticide registration to ensure registration status. All products are EPA&nbsp;registered.</p>'
    footerText.insertAdjacentHTML('afterend', footerAddition);
  }

  render() {
    const heroContent = get(this, "props.data.contentfulHero")
    const companies = get(this, "props.data.allContentfulSolutionCompany")
    const caron = get(this, "props.data.caronImg")
    const openIconBlue = get(this, "props.data.openIconBlue")
    const solutionsFooterImg = get(this, "props.data.solutionsFooterImg")
    const cornIcon = get(this, "props.data.cornIcon")
    const soyIcon = get(this, "props.data.soyIcon")
    const peanutIcon = get(this, "props.data.peanutIcon")
    const cottonIcon = get(this, "props.data.cottonIcon")
    return (
      <Layout bodyClass="solutions">
        <Seo title="SIMPAS-applied Solutions" description="Deliver multiple SIMPAS-Applied Solutions (SaS) simultaneously to address numerous agronomic issues at planting." />
        <section className="padding pt-0 hero">
          <Hero data={heroContent} custClass="img--overhang" />
        </section>

        <section className="intro">
          <div className="container">
            <p>SIMPAS-applied Solutions&trade; (SaS&trade;) are prescriptively applied in-furrow at planting, with patented SmartCartridge<sup>&reg;</sup> container technology. You can address multiple agronomic needs with our broad range of products, including fungicides, IDC treatment, insecticides, nutrients, nematicides and soil&nbsp;health&nbsp;products.</p>
            <p>The SIMPAS<sup>&reg;</sup> application system applies up to 3 SaS products – liquid or granular – in one simple&nbsp;pass.</p>
            <p>The closed delivery system creates faster, safer handling by simply swapping out containers during planned stops and eliminating opening, pouring and&nbsp;mixing.</p>
            <p>SmartCartridge containers are equipped with an RFID-enabled Smart Tag&trade; that automatically tracks and displays product levels with real-time in-cab monitoring and reporting, ensuring you pay only for as-applied product. You no longer need to worry about running containers to&nbsp;empty.</p>
            <p>Turn yield barriers into profit potential by applying only what’s prescribed, precisely where it’s&nbsp;needed.</p>
            <h2 className="padding--b">Prescriptive Technology</h2>
              <p>SIMPAS-applied Solutions are applied based on an agronomic prescription to spatially distinct probem areas in each field. Issue-specific prescriptions are developed by your retailer, agronomist or trusted advisor using a variety of factors such as sampling, sensing, historical yield data, elevation and topography. The prescription is then easily uploaded to the SIMPAS&nbsp;controller.</p>
              <p><strong>See the value in prescriptively applying multiple products in-furrow simultaneously with our helpful&nbsp;calculator.</strong></p>
              <div className="text-center">
                <a className="btn" href="/simpas-applied-solutions/solution-costs/#/forms">
                  See Annual Net Value
                </a>
              </div>
          </div>
        </section>

        {/* Dual Button Block */}
        <section className="bg--blue-dark flow--thick" id="prescriptive-tech">
          <div className="container-sm">
            <div className="padding--lr text--white">
              <h3 className="text--white padding--b">Multiple Categories and&nbsp;Brands</h3>
              <p>The SIMPAS-applied Solutions system is broadly licensed to accommodate application of multiple product categories and&nbsp;brands.</p>
              <p>Our portfolio continually expands to deliver a wider variety of granular and liquid inputs for various crops, like corn, soybeans, cotton, peanuts and more, transforming the way you treat management zones and maximizing your yield&nbsp;potential.</p>
              <p>Work with your crop inputs retailer to identify specific yield barriers and determine which SIMPAS-applied Solutions are the most effective for targeted&nbsp;treatment.</p>
              <ul className="sas-category-list">
                <li>
                  <div className={this.state.whichActive === "biostimulant" ? "solutions-class active" : "solutions-class"} onClick={this.activateSolution} onKeyDown={this.keySolution} tabIndex="0" id="biostimulant" role="tab">
                    <h4>Biostimulants</h4>
                    <img alt="" className="caron" src={caron.file.url} />
                  </div>
                  <div className="solutions-desc" role="tabpanel">
                    <div className="solutions-copy">
                      <p>Many biostimulants don’t fit seed treatment applications because of volume requirements, shelf-life stability or incompatibility with other components on the seed. SIMPAS-applied Solutions provide accurate placement of biostimulants&nbsp;in&#8209;furrow.</p>
                      <div className="solutions-products">
                        <SolutionCompany companies={companies} category="biostimulant" iconBlue={openIconBlue} curCat={this.state.whichActive} cornIcon={cornIcon} soyIcon={soyIcon} peanutIcon={peanutIcon} cottonIcon={cottonIcon} />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className={this.state.whichActive === "fungicide" ? "solutions-class active" : "solutions-class"} onClick={this.activateSolution} onKeyDown={this.keySolution} tabIndex="0" id="fungicide" role="tab">
                    <h4>Fungicides</h4>
                    <img alt="" className="caron" src={caron.file.url} />
                  </div>
                  <div className="solutions-desc" role="tabpanel">
                      <div className="solutions-copy">
                        <p>Early-season outbreaks of pathogens can cause a reduction in yield potential, but in-furrow application of fungicides fights those stressors. It is difficult to predict when you will experience either cold, wet conditions or warm, wet conditions at or immediately after planting, but the areas that tend to develop disease pressures first are low-lying areas where water can pond or heavy soils where water retention is higher. SIMPAS-applied Solutions can be applied prescriptively to low-lying or high-yielding areas to combat soilborne&nbsp;diseases.</p>
                        <div className="solutions-products">
                          <SolutionCompany companies={companies} category="fungicide" iconBlue={openIconBlue} curCat={this.state.whichActive} cornIcon={cornIcon} soyIcon={soyIcon} peanutIcon={peanutIcon} cottonIcon={cottonIcon} />
                        </div>
                      </div>
                  </div>
                </li>
                <li>
                  <div className={this.state.whichActive === "idc-treatment" ? "solutions-class active" : "solutions-class"} onClick={this.activateSolution} onKeyDown={this.keySolution} tabIndex="0" id="idc-treatment" role="tab">
                    <h4>IDC Treatments</h4>
                    <img alt="" className="caron" src={caron.file.url} />
                  </div>
                  <div className="solutions-desc" role="tabpanel">
                      <div className="solutions-copy">
                        <p>Iron deficiency chlorosis (IDC) is common in low-lying areas of a field where soil bicarbonate content and soil pH levels are high. If your soybeans have suffered from IDC, then you likely have those areas GPS mapped after observing yellow, stunted soybeans and reduced yield. Treat only acres with a history of IDC instead of the entire field with SIMPAS-applied&nbsp;Solutions.</p>
                        <div className="solutions-products">
                          <SolutionCompany companies={companies} category="idc-treatment" iconBlue={openIconBlue} curCat={this.state.whichActive} cornIcon={cornIcon} soyIcon={soyIcon} peanutIcon={peanutIcon} cottonIcon={cottonIcon} />
                        </div>
                      </div>
                  </div>
                </li>
                <li>
                  <div className={this.state.whichActive === "inoculant" ? "solutions-class active" : "solutions-class"} onClick={this.activateSolution} onKeyDown={this.keySolution} tabIndex="0" id="inoculant" role="tab">
                    <h4>Inoculants</h4>
                    <img alt="" className="caron" src={caron.file.url} />
                  </div>
                  <div className="solutions-desc" role="tabpanel">
                      <div className="solutions-copy">
                        <p>SIMPAS-applied Solutions inoculants are applied in-furrow in the zones in the field where they provide the most benefit. For example, low-lying areas or zones where water has ponded will likely benefit more from inoculants than areas where conditions are&nbsp;optimal.</p>
                        <div className="solutions-products">
                          <SolutionCompany companies={companies} category="inoculant" iconBlue={openIconBlue} curCat={this.state.whichActive} cornIcon={cornIcon} soyIcon={soyIcon} peanutIcon={peanutIcon} cottonIcon={cottonIcon} />
                        </div>
                      </div>
                  </div>
                </li>
                <li>
                  <div className={this.state.whichActive === "insecticide" ? "solutions-class active" : "solutions-class"} onClick={this.activateSolution} onKeyDown={this.keySolution} tabIndex="0" id="insecticide" role="tab">
                    <h4>Insecticides</h4>
                    <img alt="" className="caron" src={caron.file.url} />
                  </div>
                  <div className="solutions-desc" role="tabpanel">
                      <div className="solutions-copy">
                        <p>Traits aren’t performing as well as they used to. Ensure you have the protection you need by applying a soil insecticide in higher rootworm pressure environments and in fields with a history of seed-attacking insects. The SIMPAS-applied Solutions portfolio can be applied whole field, or you can treat specific&nbsp;zones.</p>
                        <div className="solutions-products">
                          <SolutionCompany companies={companies} category="insecticide" iconBlue={openIconBlue} curCat={this.state.whichActive} cornIcon={cornIcon} soyIcon={soyIcon} peanutIcon={peanutIcon} cottonIcon={cottonIcon} />
                        </div>
                      </div>
                  </div>
                </li>
                <li>
                  <div className={this.state.whichActive === "micronutrient" ? "solutions-class active" : "solutions-class"} onClick={this.activateSolution} onKeyDown={this.keySolution} tabIndex="0" id="micronutrient" role="tab">
                    <h4>Micronutrients</h4>
                    <img alt="" className="caron" src={caron.file.url} />
                  </div>
                  <div className="solutions-desc" role="tabpanel">
                      <div className="solutions-copy">
                        <p>Applying micronutrients at planting helps deliver nutritional needs for optimum plant growth potential. Micronutrient deficiencies vary across a field; SIMPAS-applied Solutions can be applied at the exact rate you need, precisely where you need&nbsp;it.</p>
                        <div className="solutions-products">
                          <SolutionCompany companies={companies} category="micronutrient" iconBlue={openIconBlue} curCat={this.state.whichActive} cornIcon={cornIcon} soyIcon={soyIcon} peanutIcon={peanutIcon} cottonIcon={cottonIcon} />
                        </div>
                      </div>
                  </div>
                </li>
                <li>
                  <div className={this.state.whichActive === "nematicide" ? "solutions-class active" : "solutions-class"} onClick={this.activateSolution} onKeyDown={this.keySolution} tabIndex="0" id="nematicide" role="tab">
                    <h4>Nematicides</h4>
                    <img alt="" className="caron" src={caron.file.url} />
                  </div>
                  <div className="solutions-desc" role="tabpanel">
                      <div className="solutions-copy">
                        <p>Most plant-parasitic nematode damage in corn occurs in hotspots rather than across the whole field. Prescriptive application of SIMPAS-applied Solutions enables efficient and precise treatment of these hotspots. Apply the nematicide where you get the most&nbsp;benefit.</p>
                        <div className="solutions-products">
                          <SolutionCompany companies={companies} category="nematicide" iconBlue={openIconBlue} curCat={this.state.whichActive} cornIcon={cornIcon} soyIcon={soyIcon} peanutIcon={peanutIcon} cottonIcon={cottonIcon} />
                        </div>
                      </div>
                  </div>
                </li>
                {/* <li>
                  <div className={this.state.whichActive === "nitrogen-fixing" ? "solutions-class active" : "solutions-class"} onClick={this.activateSolution} onKeyDown={this.keySolution} tabIndex="0" id="nitrogen-fixing" role="tab">
                    <h4>Nitrogen Fixing</h4>
                    <img alt="" className="caron" src={caron.file.url} />
                  </div>
                  <div className="solutions-desc" role="tabpanel">
                      <div className="solutions-copy">
                        <p>Don't miss opportunities to supplement synthetic nitrogen where loss is likely to occur, or in high yielding zones. Envita<sup>®</sup> fixes nitrogen in multiple crops, including corn and soybean. Make sure your crop has every chance to meet your yield objectives — apply Envita prescriptively with&nbsp;SIMPAS.</p>
                        <div className="solutions-products">
                          <SolutionCompany companies={companies} category="nitrogen-fixing" iconBlue={openIconBlue} curCat={this.state.whichActive} cornIcon={cornIcon} soyIcon={soyIcon} peanutIcon={peanutIcon} cottonIcon={cottonIcon} />
                        </div>
                      </div>
                  </div>
                </li> */}
                <li>
                  <div className={this.state.whichActive === "soil-health-management" ? "solutions-class active" : "solutions-class"} onClick={this.activateSolution} onKeyDown={this.keySolution} tabIndex="0" id="soil-health-management" role="tab">
                    <h4>Soil Health Management</h4>
                    <img alt="" className="caron" src={caron.file.url} />
                  </div>
                  <div className="solutions-desc" role="tabpanel">
                      <div className="solutions-copy">
                        <p>Biologicals create a highly productive microbial community when applied to the soil, assisting in nutrient uptake and improving fertilizer efficiency. They free up nutrients bound to soil particles, so they become more available for root uptake to boost plant access to and absorption of available nutrients. SIMPAS-applied Solutions enable whole field, variable rate or zone-specific application of biologicals to promote soil health in areas with the most to gain from soil health&nbsp;management.</p>
                        <div className="solutions-products">
                          <SolutionCompany companies={companies} category="soil-health-management" iconBlue={openIconBlue} curCat={this.state.whichActive} cornIcon={cornIcon} soyIcon={soyIcon} peanutIcon={peanutIcon} cottonIcon={cottonIcon} />
                        </div>
                      </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        
        <section className="img--full hide--mobile">
          <Img
            alt={solutionsFooterImg.description}
            fluid={solutionsFooterImg.fluid}
            loading="lazy"
          />
        </section>
        {/* End Dual Button Block */}
      </Layout>
    )
  }
}
export default SolutionsPage

export const SolutionsPageQuery = graphql`
  query SolutionsPageQuery {
    contentfulHero(heroTitle: { eq: "SIMPAS-applied Solutions™" }) {
      id
      heroTitle
      heroSubtitle
      heroDescription {
        heroDescription
      }
      backgroundImage {
        fluid(maxWidth: 1920, resizingBehavior: PAD, background: "#000", quality: 100) {
          ...GatsbyContentfulFluid
        }
        description
      }
      foregroundImage {
        file {
          url
        }
        description
      }
      foregroundOverhang
    }
    allContentfulSolutionCompany {
      edges {
        node {
          id
          copy {
            copy
          }
          linkUrl
          logo {
            description
            file {
              url
            }
          }
          name
          smartCartridgeEnabled
          order
          crops
          type
        }
      }
    }
    caronImg: contentfulAsset(title: { eq: "caron" }) {
      id
      title
      file {
        url
      }
    }
    openIconBlue: contentfulAsset(title: { eq: "open-icon-blue" }) {
      id
      title
      file {
        url
      }
    }
    cornIcon: contentfulAsset(title: { eq: "icon-corn" }) {
      id
      title
      file {
        url
      }
    }
    soyIcon: contentfulAsset(title: { eq: "icon-soybean" }) {
      id
      title
      file {
        url
      }
    }
    peanutIcon: contentfulAsset(title: { eq: "icon-peanut" }) {
      id
      title
      file {
        url
      }
    }
    cottonIcon: contentfulAsset(title: { eq: "icon-cotton" }) {
      id
      title
      file {
        url
      }
    }
    solutionsFooterImg: contentfulAsset(title: { eq: "solutions-footer" }) {
      id
      description
      title
      fluid(maxWidth: 1600, quality: 100) {
        ...GatsbyContentfulFluid
      }
      description
    }
  }
`
